<template>
  <app-header />
  <div id="wrapper">
    <app-sidebar />
    <div id="content-wrapper">
      <div class="container-fluid pb-0">
        <Suspense>
          <template #default>
            <app-contents :id="id" />
          </template>
          <template #fallback>
            <loading />
          </template>
        </Suspense>
      </div>
      <app-footer />
    </div>
  </div>
</template>

<script>
import AppFooter from "../../components/Include/AppFooter.vue";
import AppHeader from "../../components/Include/AppHeader.vue";
import AppSidebar from "../../components/Include/AppSidebar.vue";
import { defineAsyncComponent, Suspense } from "vue";
import Loading from "../../components/Include/Loading.vue";
const AppContents = defineAsyncComponent(() =>
  import("../../components/Pages/AppContents.vue")
);
export default {
  name: "Contents",
  components: {
    AppHeader,
    AppSidebar,
    AppContents,
    AppFooter,
    Loading,
    Suspense,
  },
  props: {
    id: String,
  },
};
</script>

<style>
</style>